var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"attendance-history-page"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"6"}},[_c('div',[_c('v-card',{staticClass:"mt-2 mx-1",attrs:{"outlined":"","elevation":"0"}},[_c('v-tabs',{attrs:{"fixed-tabs":"","background-color":"primary","dark":""},model:{value:(_vm.attendanceTypeTab),callback:function ($$v) {_vm.attendanceTypeTab=$$v},expression:"attendanceTypeTab"}},[_c('v-tab',{staticClass:"non-focused tab-text"},[_vm._v("Subject Attendance")]),_c('v-tab',{staticClass:"non-focused tab-text"},[_vm._v("Daily Attendance")])],1)],1),_c('intract-smart-list',{ref:"attendance-history-smart-list",staticClass:"mt-2",attrs:{"endpoint":_vm.attendanceHistoryEndpoint,"paginated":"","filter-fields":_vm.filterFields},scopedSlots:_vm._u([{key:"custom-filter--dateRange",fn:function(){return [_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.date,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"solo":"","clearable":"","value":_vm.formattedDateRangeString,"label":"Filter by Date","prepend-inner-icon":"mdi-calendar","readonly":"","hide-details":"auto"},on:{"click:clear":function($event){_vm.date = null}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-date-picker',{attrs:{"range":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[(_vm.date && _vm.date.length == 1)?_c('span',{staticClass:"text-caption red--text"},[_vm._v("Please select End Date as well")]):_vm._e()]),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","disabled":_vm.date && _vm.date.length == 1,"color":"primary"},on:{"click":function($event){return _vm.$refs.dialog.save(_vm.date)}}},[_vm._v(" OK ")])],1)],1)],1)],1)]},proxy:true},{key:"list-item",fn:function(ref){
var attendance = ref.item;
return [_c('v-list-item',{staticClass:"non-focused",attrs:{"to":{
                name: 'EditAttendance',
                params: Object.assign({}, {attendanceId: attendance.id},
                  _vm.attendanceTypeTab == 0 ? {subject: attendance.subject} : {},
                  {room: attendance.room,
                  daily: _vm.attendanceTypeTab == 1,
                  attendanceDate: _vm.moment(attendance.date).format(
                    'YYYY-MM-DD'
                  )}),
              }}},[_c('v-list-item-content',[_c('v-list-item-subtitle',[_c('p',{staticClass:"pa-0 ma-0 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.moment(attendance.date).format("ll"))+" ")])]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.attendanceTypeTab == 0 ? attendance.faculty.full_name : attendance.creator.full_name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(attendance.room.sections)+" "+_vm._s(_vm.attendanceTypeTab == 0 ? ("| " + (attendance.subject.name)) : ''))]),_c('v-list-item-subtitle',{staticClass:"text-caption"},[_vm._v("Recorded on "+_vm._s(_vm.moment(_vm.attendanceTypeTab == 0 ? attendance.created : attendance.updated).format("lll")))])],1),_c('v-list-item-action',{staticClass:"justify-center align-center"},[_c('v-row',[(_vm.attendanceTypeTab == 0)?_c('div',{attrs:{"set":(_vm.perc =
                        (attendance.attendees.length * 100) /
                        attendance.total)}},[_c('v-progress-circular',{attrs:{"value":_vm.perc,"color":"primary"}},[_c('p',{staticClass:"pa-0 ma-0 percentage-text primary--text"},[_vm._v(" "+_vm._s(Math.round(_vm.perc))+"% ")])])],1):_c('div',[_c('v-progress-circular',{attrs:{"value":attendance.attendance_percentage,"color":"primary"}},[_c('p',{staticClass:"pa-0 ma-0 percentage-text primary--text"},[_vm._v(" "+_vm._s(Math.round(attendance.attendance_percentage))+"% ")])])],1),(
                      (attendance.faculty && attendance.faculty.id == _vm.currentUser.id) ||
                      (attendance.creator && attendance.creator.id == _vm.currentUser.id) ||
                      _vm.currentUser.is_admin
                    )?_c('intract-drop-down-menu',{attrs:{"options-list":_vm.getDropdownOptions(attendance)}}):_vm._e()],1)],1)],1),_c('v-divider')]}}])})],1)])],1),_c('confirmation-dialog',{attrs:{"visible":_vm.showDeleteDialog,"title":"Are you sure you want to delete this attendance record?","description":"This action cannot be undone. This will also send a notification to the student.","delete-dialog":""},on:{"successCallback":_vm.deleteAttendance,"failureCallback":function($event){_vm.showDeleteDialog = false;
      _vm.toBeDeletedAttendance = null;}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }